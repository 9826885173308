// GA TAGS ID
export const GA_TRACKING_ID = 'UA-170136809-1'

// ADS TAGS ID
export const AW_TRACKING_ID = 'AW-357557150'
export const AW_TRACKING_CONVERSION = ''

//FACEBOOK TAGS IDS
export const FB_TRACKING_ID = ''
export const FB_PIXEL_ID = '1063419567691416'

//SENTRY TAG ID
export const SENTRY_TRACKING_ID = ''

// for meta tag <og & twitter>
export const SITE_NAME = 'JS Studio'
export const SITE_TITLE = 'JS Studio'
export const SITE_DESCRIPTION = ''
export const SITE_IMAGE = ''
export const SITE_AUTHOR = 'Advance Comunicação'
export const SITE_KEYWORDS =
  'js, JS, studio, js studio, JS studio, js Studio, Arquitetura, Interiores'
export const SITE_URL = ''
export const TWITTER_HANDLE = ''

export const CONTACT_URL = 'https://api.starlightcms.io/studiojs/forms/contact'
